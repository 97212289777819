<template>
    <div class="my-test">
        <div class="bg-box">
            <div class="bg-img">
                <img src="@/assets/57086640_p0.jpg" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "",
    data() {
        return {};
    },
    components: {},
    mounted() {
        this.bgImgOnload();
    },
    methods: {
        bgImgOnload() {
            let imgParentDom = document.querySelector(".bg-box");
            let imgDom = document.querySelector(".bg-box .bg-img img");
            imgDom.onload = () => {
                console.log("图片加载完成");
                imgParentDom.classList.add("bg-img-loaded");
            };
        }
    }
};
</script>

<style lang="less" scoped>
.my-test {
    // padding: 50px;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .bg-box {
        width: 500px;
        height: 300px;
        overflow: hidden;
    }
    .bg-img {
        width: 500px;
        height: 300px;
        position: relative;
        filter: blur(10px);
        transition: all 1s;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: url("data:image/jpeg;base64,/9j/2wBDAAgICAgJCAkKCgkNDgwODRMREBARExwUFhQWFBwrGx8bGx8bKyYuJSMlLiZENS8vNUROQj5CTl9VVV93cXecnNH/2wBDAQgICAgJCAkKCgkNDgwODRMREBARExwUFhQWFBwrGx8bGx8bKyYuJSMlLiZENS8vNUROQj5CTl9VVV93cXecnNH/wAARCAAkAEADASIAAhEBAxEB/8QAGwAAAgMBAQEAAAAAAAAAAAAABQYABAcCAwH/xAAqEAACAQMDBAEDBQEAAAAAAAABAgMABBEFEiEGE0FRIhQjMTJCYXGBkf/EABgBAQEBAQEAAAAAAAAAAAAAAAUEAwEG/8QAJBEAAgEDBQACAwEAAAAAAAAAAQIRAAMhBBIxQVEFMhQjYXH/2gAMAwEAAhEDEQA/AM+0fQDfajFbXJkt0cFt7Rn9PumxOnI4NQ0+yecMpdu5IMLiNW8Gtm1MBDBbSW6MrbexIoUNgeMcDGazPUrG7udVffGIiBg4OEX3j1TPx2ktiW7IMGeqG1LkGOYIkUrXOktbNtI53uPYwDxX2KwZhnbx7PApxTpy/wCxNPHD3I403Fz69qD+aorASATk0/aKsCA6sRzULyIJUgHihUWnxkgZz/XA/wCmmnR7W3t2YrkSNtXuqWOwE5PA/OaB317psVssLSPHMJd0kyfLagHAx5NdTdU6ei40yymzuzvmfgcYOAPdG39fpy9y0WODE9Eikbfx2q2W7gT7LujwGjvUXStprFvPdWqvFetOHHDBJV9nP7qx+bQtRiuHtZIvux8nJ+OP4NbZZa1dy2D6lqV7FEsjIkXHAUeFXyzeKSOo7qzjvpps/elRWcL+QxGcV5K6wuay4iJCjzOasZdoAJlq2C+utJNsqXjDuAfAK+91zVnSLZXsXM1rvMw5cDkKRSBPN1Pa2sL3hs7tV2h2kwO2fCueCQPdcw9Xa3EHQpCi+o+QceifdK/lWTZ2JdyWnLcf5U+x1u73tGAsfWn+1aSO3x9cysm5Oy6B8ehz4rNepbSRLSaVJIzljmGIkEec+9prw1PXr+9gb6pGQKSe4h2hgRhUO00Dv1vbaAzXkzSGWPO70Rt4qK9qL9x7qrAQ+YPPoq3QhRqLOJiZ3cDHhpelm0NIswyXMknn4BEH9csTXsLiO5hmiXTxb7YC2/DBnZfe8k0Vv/pprX6gQ5VZAZGRTFEiE4X9PmhFhdwCcHdE7mM8nc3AGcHNZH646pm1cLPbLGN3XHNMsOrS3enxLdxKJkYywnh1dsbeR44pbtFm1PUlM8awvKCqhRhR8Tgf7RH6qK9gja2hhtWkHyAOFLHj4CiKdJarbxQ373G142DuDyxQYIJArgVU3mNm6YkyZoMj9hlSOCRViyvrhWkBYussBV0cllYMcnIqvDo9pNAkrGQMfTVKlRozZyaQCKSkqDg1esy1vFexxOygFFPOd4Y/uB4OKC9V30g0a2kREUveSLgA491KlNuI0unPZST/AGsRi7dHhxSSbiSdJJHAB2/hRgVdslxe24DNhmQH+dxxUqVFJ96roAkY7FaRHpNnoyrPAhkkiYOhmw2D/mKBTavf3ExllmJaWUq3gHIqVKltEs7SZyaz1eHx4K//2Q");
            background-repeat: no-repeat;
            background-size: cover;
            // filter: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='$'%3E%3CfeGaussianBlur stdDeviation='10'/%3E%3CfeColorMatrix type='matrix' values='1 0 0 0 0,0 1 0 0 0,0 0 1 0 0,0 0 0 9 0'/%3E%3CfeComposite in2='SourceGraphic' operator='in'/%3E%3C/filter%3E%3C/svg%3E#$");
            transition: all 1s;
            opacity: 1;
        }
    }
    .bg-img-loaded {
        .bg-img {
            filter: blur(0);
            &::before {
                opacity: 0;
            }
        }
    }
}
</style>
